<template>
  <div style="min-height: 620px">
    <h3>Bitacora</h3>
    <v-container style="padding: 0">
      <v-row dense>
        <v-col cols="12" md="12">
          <v-autocomplete
            :items="$store.state.itemsBitacoraLineal"
            item-text="name"
            item-value="id"
            label="Seleccione un paso de la bitacora"
            v-model="$store.state.bitacora_id_bitacora"
          >
            <template v-slot:item="{ item }">
              <v-list-item-avatar
                :color="existeEnBitacora(item) ? 'bg-selected' : 'indigo',"
                class="text-h5 font-weight-light white--text"
              >
                {{ $store.state.itemsBitacoraLineal.indexOf(item) + 1 }}
              </v-list-item-avatar>
              <v-list-item-content class="ml-3">
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="12" style="width: 120px">
          <v-autocomplete
            :items="ObtenerComentariosPredifinidos()"
            item-text="descripcion"
            item-value="id"
            label="Seleccione un comentario predefinido"
            v-model="$store.state.bitacora_comentario_predefinido"
            :disabled="$store.state.bitacora_comentario_flag"
            content-class="custom-autocomplete-menu"
            return-object
          >
            <template v-slot:item="{ item, on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-content style="width: 120px">
                  <v-list-item-title class="wrap-text">
                    {{ item.descripcion }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="12">
          <v-checkbox
            v-model="$store.state.bitacora_comentario_flag"
            label="Ingresar un comentario manual"
            @change="_handleFlagComentarioManual"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="12" v-if="$store.state.bitacora_comentario_flag">
          <v-text-field
            v-model="$store.state.bitacora_comentario"
            label="Comentario"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="$store.state.bitacora_fecha"
                label="Fecha"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="isFormActionsDisabled"
              ></v-text-field>
            </template>
            <v-date-picker v-model="$store.state.bitacora_fecha" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save($store.state.bitacora_fecha)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="4">
          <p class="mb-1">Visible para cliente</p>
          <v-switch
            class="mt-0"
            v-model="$store.state.bitacora_visible_cliente"
            :disabled="isFormActionsDisabled"
          ></v-switch>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn
            color="primary"
            block
            :loading="loadingBotonBitacora"
            @click="_setBitacora"
            small
            >Agregar</v-btn
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-simple-table dense fixed-header height="400px">
            <template v-slot:default>
              <thead>
                <tr>
                  <!-- <th class="text-left">Grupo</th> -->
                  <th class="text-left">Nombre</th>
                  <th class="text-left">Comentario</th>
                  <th class="text-left">Fecha</th>
                  <th class="text-left">Visible/No Visible</th>
                  <th class="text-left">Acción</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in $store.state.itemsBitacora" :key="item.id">
                  <!-- <td>{{ item.namemodule }}</td> -->
                  <td>{{ item.namebitacora }}</td>
                  <td>{{ item.comentario }}</td>
                  <td>{{ item.date }}</td>
                  <td>
                    <v-switch
                      dense
                      color="primary"
                      v-model="item.visible_cliente"
                      @click="_changeStatusVisibleBitacora(item)"
                      :disabled="isFormActionsDisabled"
                    ></v-switch>
                  </td>
                  <td class="text-center">
                    <v-icon
                      class="btn_add"
                      dense
                      color="red"
                      @click.native="_deleteBitacora(item.id)"
                      :disabled="isFormActionsDisabled"
                      >mdi-delete</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-row class="align-end">
        <v-col cols="6">
          <v-btn
            @click="_generateTrackingToken"
            color="primary"
            block
            small
            :disabled="isBotonTrackingDisabled"
            :loading="loadingBotonTracking"
            >Generar Enlace Tracking</v-btn
          >
        </v-col>
        <v-col>
          <v-text-field
            v-model="$store.state.house_enlace_tracking"
            label=""
            readonly
            hide-details
          >
            <v-icon
              @click.native="
                _copyEnlaceTracking($store.state.house_enlace_tracking)
              "
              slot="append"
              color="secondary"
              class="btn_add"
            >
              mdi-content-copy
            </v-icon>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
export default {
  name: "bitacora",
  props: {
    isFormActionsDisabled: Boolean,
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    loadingBotonBitacora: false,
    loadingBotonTracking: false,
    disabledBotonBitacora: false,
  }),
  computed: {
    isBotonTrackingDisabled: function () {
      return this.$store.state.house_enlace_tracking !== "" &&
        this.$store.state.house_enlace_tracking !== null
        ? true
        : this.isFormActionsDisabled;
    },
    isBotonBitacoraDisabled: function () {
      return this.isFormActionsDisabled || this.disabledBotonBitacora;
    },
  },
  async mounted() {
    await this._getHouseBitacora().then(() => {
      this._setDefaultBitacora();
    });
  },
  methods: {
    ...mapActions([
      "_getBitacoraLineal",
      "_getHouseBitacora",
      "_getComentariosPredefinidos",
    ]),
    existeEnBitacora(item) {
      let val = this.$store.state.itemsBitacora.some(
        (v) => v.id_bitacora == item.id
      );

      // console.log(val);
      return val;
    },
    ObtenerComentariosPredifinidos() {
      let bitacora = this.$store.state.itemsBitacoraLineal.filter(
        (v) => v.id == this.$store.state.bitacora_id_bitacora
      )[0];
      return bitacora ? bitacora.comentarios : [];
    },
    cleanData() {
      this.$store.state.bitacora_id_bitacora = "";
      this.$store.state.bitacora_comentario_predefinido = "";
      this.$store.state.bitacora_comentario_flag = false;
      this.$store.state.bitacora_comentario = "";
      this.$store.state.bitacora_visible_cliente = true;
    },
    _validaData() {
      var vm = this;
      if (vm.$route.params.id) {
        vm._getBitacoraLineal();
      }
    },
    async _deleteBitacora(id) {
      var vm = this;
      var data = {
        id: id,
      };

      var config = {
        method: "post",
        url: process.env.VUE_APP_URL_MAIN + "deleteBitacora",
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios(config)
        .then(async function (response) {
          localStorage.setItem("auth-token", response.data.token);
          if (response.data.status == 200) {
            await vm._getHouseBitacora().then(() => {
              vm._setDefaultBitacora();
            });
          }

          if (response.data.status == "401") {
            Swal.fire({
              icon: "error",
              text: response.data.mensaje,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            }).then((resSwal) => {
              if (resSwal.isConfirmed && response.data.status == "401") {
                router.push({ name: "Login" });
                setTimeout(() => {
                  window.location.reload();
                }, 10);
              }
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async _changeStatusBitacora({ id, status }) {
      var vm = this;
      var data = {
        id: id,
        status: status,
      };

      var config = {
        method: "put",
        url: process.env.VUE_APP_URL_MAIN + "changeStatusBitacora",
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios(config)
        .then(async function (response) {
          localStorage.setItem("auth-token", response.data.token);
          if (response.data.status == 200) {
            await vm._getHouseBitacora();
          }

          if (response.data.status == "401") {
            Swal.fire({
              icon: "error",
              text: response.data.mensaje,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            }).then((resSwal) => {
              if (resSwal.isConfirmed && response.data.status == "401") {
                router.push({ name: "Login" });
                setTimeout(() => {
                  window.location.reload();
                }, 10);
              }
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async _changeStatusVisibleBitacora({ id, visible_cliente }) {
      var vm = this;
      var data = {
        id: id,
        visible: visible_cliente,
      };

      var config = {
        method: "put",
        url: process.env.VUE_APP_URL_MAIN + "changeStatusVisibleBitacora",
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios(config)
        .then(async function (response) {
          // console.log(response);
          localStorage.setItem("auth-token", response.data.token);
          if (response.data.status == 200) {
            await vm._getHouseBitacora();
          }

          if (response.data.status == "401") {
            Swal.fire({
              icon: "error",
              text: response.data.mensaje,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            }).then((resSwal) => {
              if (resSwal.isConfirmed && response.data.status == "401") {
                router.push({ name: "Login" });
                setTimeout(() => {
                  window.location.reload();
                }, 10);
              }
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    _setDefaultBitacora() {
      var vm = this;
      vm.disabledBotonBitacora = false;
      if (!vm.$store.state.itemsBitacora.length) {
        vm.$store.state.bitacora_id_bitacora = 1;
      } else {
        if (
          vm.$store.state.itemsBitacora.length <
          vm.$store.state.itemsBitacoraLineal.length
        ) {
          const dataUltimaBitacora =
            vm.$store.state.itemsBitacora[
              vm.$store.state.itemsBitacora.length - 1
            ];
          if (Object.keys(dataUltimaBitacora).length) {
            vm.$store.state.bitacora_id_bitacora =
              dataUltimaBitacora.id_bitacora + 1;
          }
        } else {
          vm.disabledBotonBitacora = true;
        }
      }
    },
    _handleFlagComentarioManual() {
      if (this.$store.state.bitacora_comentario_flag) {
        this.$store.state.bitacora_comentario_predefinido = null;
      }
    },
    async _setBitacora() {
      var vm = this;

      if (!vm.$store.state.bitacora_id_bitacora) {
        vm.$swal("", "¡La bitacora es requerida!", "error");
      } else {
        var ok = true;
        if (vm.$store.state.itemsBitacora.length == 0) {
          ok =
            vm.$store.state.itemsBitacoraLineal[0].id ==
            vm.$store.state.bitacora_id_bitacora;
        }

        if (vm.$store.state.itemsBitacora.length > 0) {
          let index = this.$store.state.itemsBitacoraLineal.findIndex(
            (v) => v.id == vm.$store.state.bitacora_id_bitacora
          );
          let preBitacora = this.$store.state.itemsBitacoraLineal[index - 1];
          ok = preBitacora
            ? this.$store.state.itemsBitacora.some(
                (v) => v.orden == preBitacora.orden
              )
            : true;
        }
        // return;
        if (!ok) {
          vm.$swal(
            "",
            "¡Debe registrar las bitácoras en el orden que aparecen en la lista!",
            "error"
          );
        } else if (
          vm.$store.state.bitacora_comentario_flag &&
          !vm.$store.state.bitacora_comentario
        ) {
          vm.$swal("", "¡El comentario es requerido!", "error");
        } else {
          vm.loadingBotonBitacora = !vm.loadingBotonBitacora;

          var data = {
            id_house: vm.$route.params.id,
            id_bitacora: vm.$store.state.bitacora_id_bitacora,
            id_comentario: !vm.$store.state.bitacora_comentario_flag
              ? vm.$store.state.bitacora_comentario_predefinido.id
              : "",
            comentario: vm.$store.state.bitacora_comentario_flag
              ? vm.$store.state.bitacora_comentario
              : "",
            fecha: vm.$store.state.bitacora_fecha,
            visible_cliente: vm.$store.state.bitacora_visible_cliente,
          };
          // console.log(data);
          // return;
          var config = {
            method: "post",
            url: process.env.VUE_APP_URL_MAIN + "setBitacora",
            headers: {
              "auth-token": localStorage.getItem("auth-token"),
              "Content-Type": "application/json",
            },
            data: data,
          };

          await axios(config)
            .then(async function (response) {
              localStorage.setItem("auth-token", response.data.token);
              if (response.data.status == 200) {
                await vm._getHouseBitacora().then(() => {
                  vm.cleanData();
                  vm._setDefaultBitacora();
                });
              }

              if (response.data.status == "401") {
                Swal.fire({
                  icon: "error",
                  text: response.data.mensaje,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                }).then((resSwal) => {
                  if (resSwal.isConfirmed && response.data.status == "401") {
                    router.push({ name: "Login" });
                    setTimeout(() => {
                      window.location.reload();
                    }, 10);
                  }
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            })
            .finally(function () {
              vm.loadingBotonBitacora = !vm.loadingBotonBitacora;
            });
        }
      }
    },
    async _generateTrackingToken() {
      const token = uuidv4();

      this.loadingBotonTracking = !this.loadingBotonTracking;
      await this._setTrackingToken(token);
      this.loadingBotonTracking = !this.loadingBotonTracking;
    },
    async _setTrackingToken(token) {
      var vm = this;

      var data = {
        id_house: vm.$route.params.id,
        token: token,
        fecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      };

      var config = {
        method: "put",
        url: process.env.VUE_APP_URL_MAIN + `setTrackingToken`,
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios(config)
        .then(function (response) {
          // console.log(response);
          localStorage.setItem("auth-token", response.data.token);

          if (response.data.estadoflag) {
            vm.$store.state.house_enlace_tracking =
              "https://aco.agentedecargaonline.com/tracking/" +
              response.data.data[0].token;
          } else {
            Swal.fire({
              icon: response.data.status == "401" ? "error" : "info",
              text: response.data.mensaje,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            }).then((resSwal) => {
              if (resSwal.isConfirmed && response.data.status == "401") {
                router.push({ name: "Login" });
                setTimeout(() => {
                  window.location.reload();
                }, 10);
              }
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async _copyEnlaceTracking(texto) {
      await navigator.clipboard.writeText(texto);
    },
  },
};
</script>

<style scoped>
.dataBitacora {
  max-height: 430px;
  overflow-y: scroll;
}

.btn_add {
  cursor: pointer;
}
.comentarioUtilizado {
  background: #e8eaf6;
}
.wrap-text {
  white-space: normal !important;
}
.custom-autocomplete-menu {
  width: auto;
  max-width: 500px; /* Ancho máximo */
  white-space: normal;
}
.bg-selected {
  background: #1b5e20 !important;
  color: white !important;
  width: 100%;
}
</style>
